<template>
  <div class="flex">
    <sb-render
      :key="_item._uid"
      v-for="(_item, index) in item.blocks.slice(0, 2)"
      :class="{ 'w-4/5' : index == 1, 'w-1/5' : index == 0 }"
      :item="_item" />
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'SpecialOfferItem',
  extends: Blok
}
</script>
